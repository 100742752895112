import axios from 'axios'

export const baseUrl = 'https://backend.ashahov.com'
export const serviceURL = 'http://online.ashcourse.ru:8080'

export const getData = () => {
    const url = new URL(window.location.href);
    const urlStr = (url.pathname + '/').replace('//', '/');

    // return axios.get(serviceURL + '/api/site?url=' + urlStr)

    return axios.get(baseUrl + '/api/configs/site?site=' + urlStr)
    // return axios.get(`/data.json`)
}

export const apply = (data) => {
    return axios.post(baseUrl + '/api/new-apply', data)
}

export const geo = () => {
    return axios.get('https://api.ipdata.co/?api-key=29f46259903f79a2cfc26fe2634146bf07076bd48c4e8d2616a18c67')
}

export const contact = (hash) => {
    return axios.get(baseUrl + '/api/get-contact?hash=' + hash)
}

export const payment = (applyId) => {
    return axios.post(`${baseUrl}/api/get-apply/`, { apply_id: applyId })
}
