import React, { useState, useEffect } from 'react'
import './App.css'
import FacultiesTabs from './components/FacultiesTabs/FacultiesTabs'
import Header from './components/Header/Header'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import Days from './components/Days/Days'
import VideoContainer from './components/VideoContainer/VideoContainer'
import Footer from './components/Footer/Footer'
import { Skeleton } from 'primereact/skeleton'
import { contact, getData } from './service/api'
import moment from 'moment'
import axios from 'axios'
import { Comments } from './components/Comments/Comments'
import Timer from './components/Timer/Timer'
import io from 'socket.io-client'
import { PeoplesOnline } from './components/PeoplesOnline/PeoplesOnline'

const TIMEOUT_USERS_COUNTER = 5000;
const TIMEOUT_CHECK_DATA = 1000 * 60 * 2;
let contactId = '';

const socket = io('https://socket.ashahov.ru', {
    transports: ["websocket"],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity
});
let isUs = false;

function App() {   

    const [needAuth, setAuth] = useState(false)
    
    const url = new URL(window.location.href);
    const urlStr = (url.pathname + '/').replace('//', '/');

    const [data, setData] = useState(null)
    const [dayData, setDayData] = useState(null)
    
    const [isTimer, setIsTimer] = useState(false)
    const [isMarquee, setIsMarquee] = useState(false)
    const [activeDay, setActiveDay] = useState(null)

    const [videoLink, setVideoLink] = useState(null)

    const [isTest, setIsTest] = useState(null)
    const [isComments, setIsComments] = useState(null)
    const [peoplesOnline, setPeoplesOnline] = useState(null)

    let refillPlanned = false

    let usersActiveNow = 30;

    const loadRegId = (hash) => {
        contact(hash).then((data)=>{
            contactId = data.id
        })
    }
    function findReg(){
        
        setTimeout(() => {
            if(window.hashReg && document.getElementById('reg_fr')){
                if(window.hashReg !== 'not_found' && data.registration.id === Number.parseInt(window.idReg)) {
                    loadRegId(window.hashReg)
                } else {
                    setAuth(data?.site?.preentry)
                }
            } else {
                findReg()
            } 
        }, 200);
    }

    socket.on('uses-online', (data) => {
        usersActiveNow = data
    });

    socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
            socket.connect();
        }
    });

    const peoplesOnlineChange = (us) => {
        setPeoplesOnline(us);
        setTimeout(() => peoplesOnlineChange(usersActiveNow), TIMEOUT_USERS_COUNTER)
    }


    useEffect(() => {
        if (!isUs && data && data.site && data.site.room) {
            socket.emit('room', data?.site?.room);
            peoplesOnlineChange();
            findReg();

            isUs = true;            
        }
    }, [data])


    useEffect(() => {
        // getData().then((res) => setData(res.data.data))
        requestData()

        // ОБРАЗЕНИЕ К АПИ МОЖЕТ ЧЕРЕЗ ЗЕРКАЛА БЫТЬ
    }, [])

    const requestData = () => {
        getData().then((res) => {
            if (res?.data?.data?.site?.is_redirect) {
                window.location.href = res.data.data.site.redirect_url;
            } else {
                document.title = res.data?.data?.site?.title;
                window.room = res.data?.data?.site?.room
                window.cackle_id = res.data?.data?.site?.cackle_id
                res.data.data.current_time = res.data.current_time
                setData(res.data.data)
                if(activeDay === null) setActiveDay(res.data.data.site.active_day)
                let url = new URL(window.location.href)
                setIsTest(!!url.searchParams.get('test'));
                setIsComments(!!url.searchParams.get('comments'));
            }
            //Устанавливаем цвет темы
            document.documentElement.style.setProperty('--main-color', res.data?.data?.site?.color)
        })
        setTimeout(() => {
            requestData()
        }, TIMEOUT_CHECK_DATA)
    }

    const refillData = (data) => {
        if (data) {
            const currentTime = moment().format()

            /*  console.log(currentTime) */
            const timerStart = moment(data?.timer_start).format()
            const timerFinish = moment(data?.timer_finish).format()
            const showTimer = moment(currentTime).isBefore(timerFinish) && moment(currentTime).isAfter(timerStart)

            const marqueeStart = moment(data?.marquee_start).format()
            const marqueeFinish = moment(data?.marquee_finish).format()
            const showMarquee = moment(currentTime).isBefore(marqueeFinish) && moment(currentTime).isAfter(marqueeStart)

            setIsTimer(showTimer)
            setIsMarquee(showMarquee)
        }
        refillPlanned = setTimeout(() => refillData(data), 1000)
    }



    useEffect(() => {
        setDayData(data?.days[data?.site?.active_day || 0])
        if (refillPlanned) {
            clearTimeout(refillPlanned)
        }
        refillData(data?.days[data?.site?.active_day || 0])
    }, [data])

    const selectDay = (selectedDay) => {
        setVideoLink(selectedDay?.video_link)
        refillData(data?.days[data?.site?.active_day || 0])
        setActiveDay(selectedDay.idx)
    }

    const u = '&' + (['utm_source','utm_campaign','utm_medium','utm_content','utm_term'].map(utm => { const s = (new URL(window.location)).searchParams.get(utm); return utm + '=' + ( s ? (s) : '') } ).join('&'));

    return (
        <>
            {
            needAuth && <div style={{width: '100%',height: '100vh'}}>
                {data.registration && <iframe className =" flex justify-content-center" title='reg' style={{border: 'none', minWidth: '360px', margin:'30px auto', height: '100%'}} src={'https://reg.ashahov.com/register?id='+data.registration.id+'&extended=true&color=222&border=222&bg=f5f5f5&redirect='+window.location.href+'&redirect_parent=Y' + u}></iframe>}
            </div>
            }
            {!needAuth && <div className="grid m-0">
                {isComments && <div className="col-12 mt-3 p-0 flex justify-content-center">
                    <div className="tabs" id="mc-container"></div>
                </div>}
                {!isComments && <>
                    <div className="col-12 hidden lg:block" style={{ padding: '0', zIndex: '9999' }}>
                        <div className="col-12 p-0 lg:fixed left-0">
                            <Header data={data} />
                        </div>
                    </div>
                    <div className="col-12 lg:col-8 lg:pt-8 lg:mt-4 py-0">
                        <div className="col-12 p-0 lg:col-8 lg:fixed flex flex-column">
                            <div className='hidden lg:block'>
                                <Days data={data} selectDay={selectDay} peoplesOnline={peoplesOnline} />
                            </div>
                            {data && <VideoContainer isMarquee={isMarquee} isTimer={isTimer} data={data} activeDay = {activeDay} dayData={dayData} selectDay={selectDay} videoLink={videoLink} peoplesOnline={peoplesOnline} />}
                        </div>
                    </div>
                    <div className="col-12 lg:col-4 flex pt-0 mt-0 lg:pt-8  lg:mt-5 lg:pl-6" style={{ flexDirection: 'column' }}>
                        <div className='lg:hidden'>
                            <Days data={data} selectDay={selectDay} peoplesOnline={peoplesOnline} />
                        </div>
                        {(data?.forms && data?.forms.length > 0) &&
                            <FacultiesTabs data={data} isTimer={isTimer} dayData={dayData} />}
                        {!data && <Skeleton width="85%" height="750px" className="mb-3" />}
                        <div className="col-12 p-0 flex justify-content-start">
                            {data ? <>
                                {(data && data?.site?.cackle_id) ?
                                    <div className='col-12'>
                                        {peoplesOnline && <span className='hidden lg:block mb-3 '>Сейчас онлайн: {peoplesOnline + 30}</span>}
                                        <div className="tabs" id="mc-container"></div>
                                    </div>
                                    :
                                    <Comments data={data} peoplesOnline={peoplesOnline} className={(data?.forms && data?.forms.length > 0) ? 'forms' : 'no-forms'} />
                                }

                            </>
                                :
                                <Skeleton width="85%" height="60px" />}
                        </div>
                    </div>
                </>
                }
                <Footer data={data} />
            </div>}
        </>
    )
}

var searchParams = new URLSearchParams(window.location.href);

const findDataInLocalStorageOrParams = (elem) => {
    if (localStorage.getItem(elem) !== "null" && localStorage.getItem(elem)) {
        return localStorage.getItem(elem)
    } else {
        let queryParam = searchParams.get(elem) ? searchParams.get(elem) : null
        if (queryParam) {
            localStorage.setItem(elem, queryParam)
        }
        return queryParam
    }
}

const findUtms = (utm) => {
    return searchParams.get(utm);
}

const seachUserData = () => {
    let userData = {}
    let expectedData = ['email', 'leeloo_account_id', 'gr_id']
    let expectedUtms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

    expectedData.forEach(element => {
        userData[element] = findDataInLocalStorageOrParams(element)
    })

    expectedUtms.forEach(utm => {
        userData[utm] = findUtms(utm)
    })
    const url = new URL(window.location.href);
    let urlStr = (url.pathname);
    if (urlStr.slice(-1) === '/') {
        urlStr = urlStr.substring(0, urlStr.length - 1);
    }
    userData['url'] = contactId || userData['email'];
    userData['path'] = urlStr;
    return userData
}

const logUserData = (data) => {
    axios.post('/log/handle.php', data)
}

logUserData(seachUserData())
setInterval(() => {
    logUserData(seachUserData())
}, 2 * 60 * 1000)

export default App
