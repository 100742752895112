import React, { useState } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import PhoneInput from 'react-phone-number-input'
import { apply, geo } from '../../service/api'
import { Payment } from '../Payments/Payment'
import Timer from '../Timer/Timer'

let isGeo = false;
const geoData = {}

const Tab = (props) => {
    var searchParams = new URLSearchParams(window.location.href);
    if (!isGeo) {
        isGeo = true;
        geo().then(({ data }) => {
            geoData['city'] = data.city;
            geoData['country'] = data.country_name;
            geoData['timezone'] = data.time_zone.name;
        })
            .catch(error => {
                console.log(error);
            });
    }
    const [payment, setPayment] = useState('fullPayment')
    const [paymentData, setPaymentData] = useState(null)

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSuccessful, setIsSuccessful] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('Пожалуйста, введите ваше имя')
    const [nameDirty, setNameDirty] = useState(false)

    const [email, setEmail] = useState('')
    const [emailDirty, setEmailDirty] = useState(false)
    const [emailError, setEmailError] = useState('Пожалуйста, введите ваш E-mail')

    const [phone, setPhone] = useState('')
    const [phoneDirty, setPhoneDirty] = useState(false)
    const [phoneError, setPhoneError] = useState('Пожалуйста, введите ваш номер телефона')
    const [isButtonLoading, setIsButtonLoading] = useState(false)

    const formatNumber = (number) => {
        return Intl.NumberFormat('ru-RU').format(number)
    }

    const discountPercent = () => {
        if (!props.item.price_product_old) return '';
        return '(-' + ((props.item?.discount_amount * 100) / props.item?.price_product_old).toFixed(0) + '%)'
    }

    const discountProduct = () => {
        if (props.item?.discount_product && typeof props.item?.discount_product === 'object') {
            let formated = Object.keys(props.item?.discount_product).map((product, index) => {
                return <div className='flex justify-content-center mt-2' key={index}>+ {props.item?.discount_product[product].split('+')[1]}</div>
            })
            return formated
        }
    }

    const content = (block) => {
        const mapped = Object.entries(block).map(([k, v]) => {
            return (
                <div key={v} className="mb-3 tab-content">
                    {k.indexOf('li') === -1 && <span className='tab-content__title mr-2'>{k}</span>}
                    <p dangerouslySetInnerHTML={{ __html: (k.indexOf('li') > -1 ? '<span class="tab-content__li">✪</span>' : '') + v }}></p>
                </div>
            )
        })
        return mapped
    }

    const validateName = (name) => {
        setName(name)
        if (name.length < 2) {
            setNameError('Имя должно быть длиннее 2-х символов')
            if (!name) {
                setNameError('Пожалуйста, введите ваше имя')
            }
        } else {
            setNameError('')
        }
    }

    const validateEmail = (email) => {
        setEmail(email)
        const isValid = String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        isValid ? setEmailError('') : setEmailError('Некорректный Email')
    }

    const validatePhone = (phone) => {
        setPhone(phone)
        if (phone?.length < 10 || !phone) {
            setPhoneError('Введен некорректный номер телефона')
            if (!phone) {
                setPhoneError('Пожалуйста, введите ваш номер телефона')
            }
        } else {
            setPhoneError('')
        }
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'name':
                setNameDirty(true)
                break
            case 'email':
                setEmailDirty(true)
                break
            case 'phone':
                setPhoneDirty(true)
                break
            default: break;
        }
    }

    const submitForm = () => {
        if (nameError || emailError || phoneError) {
            setNameDirty(true)
            setEmailDirty(true)
            setPhoneDirty(true)
        } else {
            const userData = Object.assign({ email, name, phone, apply_config_id: payment === "fullPayment" ? props.item.apply_config_id : props.item.prepay_product_id }, geoData);
            userData['utm_source'] = searchParams.get('utm_source');
            userData['utm_campaign'] = searchParams.get('utm_campaign');
            userData['utm_medium'] = searchParams.get('utm_medium');
            userData['utm_content'] = searchParams.get('utm_content');
            userData['utm_term'] = searchParams.get('utm_term');
            setIsButtonLoading(true)
            apply(userData).then(payments => {
                if (props.item.is_credit && payment !== "fullPayment") {
                    setIsSuccessful(true)
                } else {
                    setIsSubmitted(true);
                }
                setIsButtonLoading(false)
                setPaymentData(payments.data)
                window.scrollTo(0, 0);
            })
        }
    }

    return (
        <div className="tab">
            {
                (!isSubmitted && !isSuccessful) && <>
                    <div className="mb-5">
                        {props.item?.course_start_title && <div className="startDay">
                            <div>{props.item?.course_start_title}</div> <div style={{ fontWeight: 'bold' }}>{props.item?.course_start_value}</div>
                        </div>
                        }
                        <hr className="mb-3" />

                        {/*  {!props.isTimer && <Timer timerFinish={props.dayData?.timer_finish} />} */}

                        {props.item?.price_product_current && <>
                            <div className="flex align-items-end flex-wrap justify-content-between mb-2">
                                <div className="flex flex-column">
                                    {props.item?.price_product_old && <div className="oldPrice mb-0">{formatNumber(props.item?.price_product_old)} ₽ </div>}
                                    <div className="newPrice">{formatNumber(props.item?.price_product_current)}&nbsp;₽</div>
                                </div>

                                {/*  <div className="discountProduct">
                                    <span>{discountProduct()}</span>
                                </div> */}
                                {props.item?.discount_amount > 0 && <div className="profit mb-2">
                                    выгода {formatNumber(props.item?.discount_amount)} ₽ {discountPercent()}
                                </div>}
                            </div>


                        </>
                        }
                    </div>
                    {props.item?.price_product_current &&
                        <div className="mb-3 flex tab-payment">
                            <div className="field-radiobutton  mr-6">
                                <RadioButton inputId="payment1" name="payment" value="fullPayment" onChange={(e) => setPayment(e.value)} checked={payment === 'fullPayment'} />
                                <label htmlFor="payment1">{props.site?.title_full_price}</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton inputId="payment2" name="payment" value="installmentPayment" onChange={(e) => setPayment(e.value)} checked={payment === 'installmentPayment'} />
                                <label htmlFor="payment2">{props.site?.title_installment_price}</label>
                            </div>
                        </div>
                    }
                    <div className="mb-5 tab-booking-form">
                        <div className="field mb-4">
                            <InputText required name="name" type="text" value={name} onChange={(e) => validateName(e.target.value)} onBlur={(e) => blurHandler(e)} className="w-full " placeholder="Ваше имя*" />
                            {nameDirty && nameError && <small className="p-error block">{nameError}</small>}
                        </div>
                        <div className="field mb-4">
                            <InputText required name="email" type="email" value={email} onChange={(e) => validateEmail(e.target.value)} onBlur={(e) => blurHandler(e)} className="w-full" placeholder="E-mail*" />
                            {emailDirty && emailError && <small className="p-error block">{emailError}</small>}
                        </div>
                        <div className="field mb-5">
                            <PhoneInput className="phone w-full" required name="phone" placeholder="Телефон*" value={phone} onChange={(e) => validatePhone(e)} onBlur={(e) => blurHandler(e)} />
                            {phoneDirty && phoneError && <small className="p-error block">{phoneError}</small>}
                        </div>
                        <Button className={props.isTimer ? "w-full flex justify-content-between" : "w-full flex justify-content-center"} onClick={() => submitForm()} style={{ boxShadow: `0px 10px 25px 0px ${props.site.color}4D` }} >
                            {isButtonLoading &&
                                <i className="pi pi-spin pi-spinner mr-2" style={{ 'fontSize': '1em' }}></i>}
                            <span>{props.item.submit_button_title}</span>
                            {props.isTimer && <Timer timerFinish={props.dayData?.timer_finish} />}
                        </Button>
                        <div className="discountProduct">
                            <span>{discountProduct()}</span>
                        </div>


                    </div>
                    <div>
                        {props.item?.content?.map((block, index) => {
                            return <div key={index} className={index === 0 ? "mb-5 first-content-block" : "mb-5"}>{content(block)}</div>
                        })}
                    </div>
                    <div className='text-center mt-3'>
                        <button className='hide-form_btn' onClick={() => props.setShowForm(false)}>Скрыть форму</button>
                    </div>
                    {/* {props.item?.price_product_current &&
                        <div className="mb-3 flex tab-payment">
                            <div className="field-radiobutton  mr-6">
                                <RadioButton inputId="payment1" name="payment" value="fullPayment" onChange={(e) => setPayment(e.value)} checked={payment === 'fullPayment'} />
                                <label htmlFor="payment1">{props.site?.title_full_price}</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton inputId="payment2" name="payment" value="installmentPayment" onChange={(e) => setPayment(e.value)} checked={payment === 'installmentPayment'} />
                                <label htmlFor="payment2">{props.site?.title_installment_price}</label>
                            </div>
                        </div>
                    }
                    <div className="mb-5 tab-booking-form">
                        <div className="field mb-4">
                            <InputText required name="name" type="text" value={name} onChange={(e) => validateName(e.target.value)} onBlur={(e) => blurHandler(e)} className="w-full " placeholder="Ваше имя*" />
                            {nameDirty && nameError && <small className="p-error block">{nameError}</small>}
                        </div>
                        <div className="field mb-4">
                            <InputText required name="email" type="email" value={email} onChange={(e) => validateEmail(e.target.value)} onBlur={(e) => blurHandler(e)} className="w-full" placeholder="E-mail*" />
                            {emailDirty && emailError && <small className="p-error block">{emailError}</small>}
                        </div>
                        <div className="field mb-5">
                            <PhoneInput className="phone w-full" required name="phone" placeholder="Телефон*" value={phone} onChange={(e) => validatePhone(e)} onBlur={(e) => blurHandler(e)} />
                            {phoneDirty && phoneError && <small className="p-error block">{phoneError}</small>}
                        </div>
                        <Button className="w-full" label={props.item.submit_button_title} onClick={() => submitForm()} />
                        <div className='text-center mt-3'>
                            <button className='hide-form_btn' onClick={() => props.setShowForm(false)}>Скрыть форму</button>
                        </div>

                    </div> */}
                </>
            }
            {
                (paymentData && paymentData.payment_types.length > 0 && isSubmitted) &&
                <>
                    <div className="w-full">
                        <Button onClick={() => setIsSubmitted(false)} style={{ float: "right" }} icon={<i className="pi pi-times"></i>} className=" flex p-button-rounded p-button-sm p-button-outlined p-button-secondary"></Button>
                    </div>

                    <Payment dataSite={props.site} email={email} {...paymentData}></Payment>
                </>
            }
            {
                (isSuccessful) && (
                    <div className='pt-8 pb-8' style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: props?.item?.success_apply_message || 'Ваша заявка отправлена нашему менеджеру, он свяжется с Вами в ближайшее врем' }}>
                    </div>
                )
            }
        </div>
    )
}

export default Tab
