import React, { useState } from 'react'
import { Skeleton } from 'primereact/skeleton'
import './Days.scss'
import { Dropdown } from 'primereact/dropdown'
import { useEffect } from 'react'
import { PeoplesOnline } from '../PeoplesOnline/PeoplesOnline'

const Days = (props) => {

    const countDays = props.data?.days?.length
    const activeDay = props.data?.site?.active_day !== false ? props.data?.site?.active_day : 365
    const canShowPrevDays = props.data?.site?.can_show_prev_days
    const [day, setDay] = useState(null)

    const dayClass = (idx) => {

        if(props.data?.site.type === 'rec'){
            if (day === idx) {
                return 'day day-active'
            }
            return 'day day-past'
        }

        if (countDays === 1) {
            return 'day day-one'
        }
        if (canShowPrevDays === true) {
            if (activeDay === idx) {
                return 'day day-active'
            }
            if (activeDay >= idx) {
                return 'day day-past'
            }
            return 'day day-future'
        } else if (canShowPrevDays === false) {
            if (activeDay === idx) {
                return 'day day-active 3'
            }
            if (idx > activeDay) {
                return 'day day-future'
            }
            return 'day day-past-unavailable 3'
        }
    }

    const options = props?.data?.days?.map((item, idx) => ({ idx: idx, day: idx + 1, ...item }))

    useEffect(() => {
        options?.map((item, idx) => {
            if (idx === activeDay) {
                setDay(item)
            }
        })
    }, [props.data])

    const optionTemplate = (option) => {

        return (
            <div className={`flex align-items-center justify-content-start ${dayClass(option.idx)}`} >
                <h3 className='mr-2'>День {option.day}</h3> 
                {/* <h3 className="mr-2">{activeDay > option.idx ? '(Пройден)' : ''}</h3> */}
                <span>{option.title}</span>
            </div>
        );
    }


    const selectedDayTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div className='mr-2 selected-day'>День {option.day} </div>
                    {/* {activeDay > option.idx ? '(Пройден)' : ''} */}
                    <div className='selected-title mt-1 mx-auto'>{option.title}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const selectDay = (option) => {

        if(props.data?.site.type === 'rec'){
            return props.selectDay(option)
        }
        if (canShowPrevDays) {
            if (option.idx > activeDay) {
                return null
            }
            setDay(option)
            return props.selectDay(option)
        } else {
            if (option.day > activeDay) {
                if (option.idx > activeDay) {
                    return null
                }
                setDay(option)
                return props.selectDay(option)
            }
        }
    }



    const selectedDayHandler = (e) => {
        setDay(e.value)
        selectDay(e.value)
    }

    return (
        <div className='grid m-0 align-items-center'>
            <div className="col-12">
                {props?.data && (
                    <div className="header-title justify-content-between">
                        <div>
                            <img alt="logo2" src={'/assets/logo2.png'} />
                            <div>
                                <h2
                                    dangerouslySetInnerHTML={{
                                        __html: props.data?.site?.title,
                                    }}
                                ></h2>
                                <p>{props.data?.days?.length === 1 && <> <p>{props.data?.days[0].title}</p></>}</p>
                            </div>
                        </div>
                        <div className="block lg:hidden">
                            <PeoplesOnline data={props.data} peoplesOnline={props.peoplesOnline} />
                        </div>

                    </div>
                )}
                {!props.data && (
                    <div className="header-title">
                        <div>
                            <Skeleton width="500px" height="40px" borderRadius="16px" />
                        </div>
                    </div>
                )}
            </div>
            {(props.data && props.data?.days?.length > 1) && (
                <>
                    {/* <div className="days">
                        {props.data?.days.map((day, index) => (
                            <Day
                                onClick={() => props.selectDay(day)}
                                countDays={props.data?.days.length}
                                key={day.id} day={index + 1} index={index}
                                title={day.title}
                                activeDay={props.data.site.active_day !== false ? props.data.site.active_day : 365}
                                canShowPrevDays={props.data?.site.can_show_prev_days}
                            />
                        ))}
                    </div> */}
                    <div className="col-12 lg:col-5">
                        <div className='select-day-container'>
                            <Dropdown disabled={!props.data.site.can_show_prev_days} className='select-days' value={day} options={options} onChange={(option) => selectedDayHandler(option)} optionLabel="title" valueTemplate={selectedDayTemplate} itemTemplate={optionTemplate} placeholder="Выберите день" />
                        </div>

                    </div>

                </>
            )}

            {!props.data && <Skeleton width="100%" height="100px" className="mb-3" />}
        </div>
    )
}

export default Days
