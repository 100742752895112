import React from 'react'

const Footer = ({ data }) => {
    // console.log(data)
    return (
        <div className="col-12 flex justify-center  lg:hidden">
            <div className="header-logo">
                <img alt="logo" src={'/assets/logo2.png'} />
                <div>
                    {data?.site?.company === 'OOO' && <h1 style={{fontWeight: 'normal'}}>ИНСТИТУТ ПРАКТИЧЕСКОЙ СОЦИАЛЬНОЙ ПСИХОЛОГИИ <b>АЛЕКСАНДРА ШАХОВА</b></h1>}
                    {data?.site?.company !== 'OOO' && <h1 style={{fontWeight: 'normal'}}>ПСИХОЛОГ <b>АЛЕКСАНДР ШАХОВ</b></h1>}                
                </div>
            </div>
        </div>
    )
}

export default Footer
