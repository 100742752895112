import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useEffect, useRef, useState } from "react"
import Gravatar from "react-gravatar"

export const Comment = ({ data, comment, replies, setDataComments, dataComments, comments, reply, saveHandler, user, scrollBottom, canReply }) => {
    const [showReply, setShowReply] = useState(false)
    const [text, setText] = useState('')

    const onEnterSend = (e, item) => {
        if (e.keyCode === 13) {
            sendHandler(item)
            setShowReply(false)
        }

    }

    const sendHandler = (item) => {
        const parentId = item.parentId ? item.parentId : item.id
        console.log(item.username)
        setDataComments([{ id: dataComments.length + 1, admin: JSON.parse(localStorage.getItem("user")).admin, username: JSON.parse(localStorage.getItem("user")).name, parentId: parentId, parentName: item.username, email: JSON.parse(localStorage.getItem("user")).email, text: text, createdAt: new Date() }, ...dataComments])
        setShowReply(false)
        setText('')
    }

    const getReplies = (id) => {
        return comments?.filter((item) => item.parentId === id).sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        })
    }

    const daysFunction = (value) => {
        const date1 = new Date(value)
        const date2 = new Date()
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

        const wordDays = () => {
            if (diffDays === 2 || diffDays === 3 || diffDays === 4) {
                return `${diffDays} дня назад`
            } else {
                return `${diffDays} дней назад`
            }
        }


        if (diffDays > 0) {
            if (diffDays === 1) {
                return 'день назад'
            } else {
                return wordDays()
            }
        } else {
            return ''
        }
    }

    function addZero(val) {
        if (val < 10) {
            return '0' + val;
        }
        return val;
    };

    const replyHandler = () => {
        setShowReply(!showReply)
        setText(`${comment.username}, `)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }

    useEffect(() => {
        scrollToBottom()
    }, []);

    const isDesktop = () => {
        return window.innerWidth >= 992
    }

    const messagesEndRef = useRef(null)

    const IconBtn = ({ color }) => {
        return <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.423361 19.5728C1.00128 20.1638 1.86815 20.0956 2.85358 19.6485L20.5393 11.5637C20.9913 11.3591 21.3543 11.1394 21.6062 10.8818C22.1323 10.3438 22.1323 9.66183 21.5988 9.11628C21.3543 8.86623 20.9913 8.63134 20.5393 8.42675L2.77207 0.319179C1.89038 -0.0824111 1.00869 -0.16576 0.43077 0.42526C-0.273102 1.14509 -0.0137815 1.95585 0.490044 2.92573L3.15735 8.0479C3.51299 8.745 3.82418 9.04808 4.52805 9.07081L20.4282 9.69214C20.6282 9.69972 20.7394 9.84369 20.7468 10.0028C20.7542 10.1619 20.6208 10.2983 20.4356 10.3059L4.53546 10.9575C3.87604 10.9954 3.5204 11.2985 3.15735 11.988L0.534499 17.0041C0.00103758 18.0043 -0.287921 18.8454 0.423361 19.5728Z" fill={color} />
        </svg>

    }

    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return `<a href="${url}">${url}</a>`;
        })

    }

    const texxxt = "Find me at http://www.example.com and also at http://stackoverflow.com";

    return <>
        <div className="comment-img-container">
            <Gravatar
                email={comment?.email}
                size={40}
                rating="pg"
                default="identicon"
                //default="monsterid"
                //default="wavatar"
                //default="mm"
                //default="robohash"
                className="CustomAvatar-image mr-2"
            />
        </div>
        <div className="comment-right-part w-full">
            <div className="comment-header">

                {reply ? <div className="w-full">
                    <div className="flex align-items-center">
                        <div className="comment-author mb-0 mr-3">{comment.username}</div>
                        {comment.admin &&
                            <div>
                                <div className="comment-admin w-max px-1">Admin</div>
                            </div>

                        }
                    </div>

                    <div className="flex align-items-center justify-content-between">
                        <div className="flex">
                            <div className="comment-date mr-2">Ответ</div>

                            <div className="flex align-items-center">
                                <div className="comment-date mb-0 mr-3">{comment.parentName}</div>
                            </div>
                        </div>

                    </div>


                </div>
                    :
                    <>
                        <div className="flex align-items-center">
                            <div className="comment-author mb-0 mr-3">{comment.username}</div>
                            {comment.admin &&
                                <div>
                                    <div className="comment-admin w-max px-1">Admin</div>
                                </div>

                            }
                        </div>
                    </>

                }

            </div>
            {comment.admin ?
                <div className="comment-text" dangerouslySetInnerHTML={{ __html: urlify(comment.text) }}></div>
                :
                <div className="comment-text">{comment.text}</div>}
            {user &&
                <div className={canReply ? 'flex align-items-center justify-content-between' : 'flex align-items-center justify-content-end'}>
                    {canReply &&
                        <Button className='reply mb-0' onClick={replyHandler}>Ответить</Button>}
                    <div className="comment-date">{daysFunction(comment.createdAt)} {addZero(new Date(comment.createdAt).getHours())}:{addZero(new Date(comment.createdAt).getMinutes())}</div>
                </div>
            }

            {showReply &&
                <>

                    <div className="flex write-comment p-0 mt-0 lg:mt-3">
                        <InputText className="write-comment-input w-full mr-3" value={text} autoFocus onChange={(e) => setText(e.target.value)} onKeyDown={(e) => onEnterSend(e, comment)} placeholder='Написать комментарий...' />
                        <button disabled={!text} className="send-comment-btn" onClick={() => sendHandler(comment)}>
                            <IconBtn color={data?.site?.color} />
                        </button>
                    </div>


                </>
            }
            {replies?.length > 0 && (
                <div className="replies">
                    {replies.map((item) => (
                        <div key={item.id} className="flex mt-2">
                            <Comment saveHandler={saveHandler} comment={item} replies={[]} reply={true} setDataComments={setDataComments} dataComments={dataComments} comments={comments} user={user} canReply={false} />
                        </div>
                    ))}
                </div>
            )}
        </div>

    </>

}