import React from 'react'
import { Skeleton } from 'primereact/skeleton'
import './Header.scss'

const Header = (props) => {
    return (
        <div className="header">
            <div className="hidden lg:flex lg:col-4">
                {props.data && (
                    <div className="header-logo">
                        <img alt="logo" src={'/assets/logo2.png'} />
                        <div>
                            {props.data?.site?.company === 'OOO' && <h1 style={{fontWeight: 'normal'}}>ИНСТИТУТ ПРАКТИЧЕСКОЙ СОЦИАЛЬНОЙ ПСИХОЛОГИИ <b>АЛЕКСАНДРА ШАХОВА</b></h1>}
                            {props.data?.site?.company !== 'OOO' && <h1 style={{fontWeight: 'normal'}}>ПСИХОЛОГ <b>АЛЕКСАНДР ШАХОВ</b></h1>}
                            {/* <h1 style={{fontWeight: 'normal'}} dangerouslySetInnerHTML={{__html:props.data?.site.company}}></h1> */}
                        </div>
                    </div>
                )}
                {!props.data && (
                    <div className="header-logo">
                        <Skeleton width="50px" height="50px" borderRadius="50%" className="mr-3" />
                        <div>
                            <Skeleton width="280px" height="40px" borderRadius="16px" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Header
