import React, { useState, useEffect } from 'react'
import Marquee from 'react-double-marquee'
import { Dialog } from 'primereact/dialog'
import './VideoContainer.scss'
import { Skeleton } from 'primereact/skeleton'
import Days from '../Days/Days'
import { Button } from 'primereact/button'
import YouTube from 'react-youtube'
import plural from 'plural-ru'
const VIDEO_URL_BASE = 'https://s1.psy-ash.com/';

const waitTime = (time)=> {
    if(time > 24 * 60 * 60){
        return Math.ceil(time / (24 * 60 * 60)) + plural(Math.ceil(time / (24 * 60 * 60)), ' день',' дня',' дней') 
    }
    if(time > 60 * 60){
        return Math.ceil(time / (60 * 60)) + plural(Math.ceil(time / ( 60 * 60)), ' час',' часа',' часов') 
    } 
    if(time > 60) {
        return Math.ceil(time / 60) + plural(Math.ceil(time / ( 60)), ' минуту',' минуты',' минут') 
    } 
    return time + plural(time, ' секунду',' секунды',' секунд') 
}

let url = new URL(window.location.href)
let events = false;
let isAutoStarted = false;
let timer = 0;
const VideoContainer = (props) => {
    const [liveLink, setVideoLink] = useState(null)
    const [isBanner, setIsBanner] = useState(false)
    const [autoLink, setAutoLink] = useState('')

    const [showDialog, setShowDialog] = useState(false)
    const [opacity, setOpacity] = useState(1)

    const [timeBefore, setTimeBefore] = useState(0)

    useEffect(() => {
        if (props.dayData?.is_preview_video) {
            setVideoLink(props.dayData?.preview_video)
        } else if (props.videoLink) {
            if (props.videoLink && props.videoLink.indexOf('//') > -1) {
                setVideoLink(props.videoLink)
            } else {
                setVideoLink(null)
            }
        } else if (props.dayData?.video_link) {
            if (props.dayData?.video_link && props.dayData?.video_link.indexOf('//') > -1) setVideoLink(props.dayData.video_link)
        }

        if(props?.data?.site?.type === 'auto' ){            
            const time = ((props.data.current_time * 1000) - (new Date(props.data.days[props.activeDay].start_webinar)).getTime()) / 1000;
            setAutoLink(VIDEO_URL_BASE + props.data.registration.code + props.activeDay+'.mp4')
            
            if(time < 0) {
                setAutoLink(false)
                setTimeBefore(-1 * time)
            } else {
                if(!isAutoStarted){
                    setTimeout(()=>{
                        isAutoStarted = true;
                        const elVideo = document.getElementById('_vd');
                        elVideo.currentTime = time;
                        elVideo.onended = () => {setTimeout(()=>{if(props.dayData?.redirect_after) window.location.href = props.data.site.redirect_url}, 3000)} 
                        elVideo.oncontextmenu = (e) => {return false}
                    }, 0)
                }
            }
        }
    }, [props])

    useEffect(() => {
        setIsBanner(false);
    }, [liveLink])

    useEffect(() => {
        clearTimeout(timer);
        timer = setTimeout(()=>{
            if(timeBefore > 0){
                setTimeBefore(timeBefore - 1)
            } else {
                setAutoLink(VIDEO_URL_BASE + props.data?.registration?.code + props.activeDay+'.mp4')
            } 
        }, 1000)
    }, [timeBefore])

    useEffect(() => {
        setAutoLink(VIDEO_URL_BASE + props.data?.registration?.code + props.activeDay +'.mp4')
    }, [props.activeDay])

    const controls =  ((url.searchParams.get('controls')==='Y' || props?.data?.site?.type === 'rec') ? 1 : 0);

    
    // console.log('Текущая ссылка? ', videoLink)

    const getYoutubeCode = (liveLink) => {
        if (liveLink) {
            if (liveLink.toLowerCase().indexOf('.png') > -1 || liveLink.toLowerCase().indexOf('.jpg') > -1) {
                setIsBanner(true);
                return liveLink
            }
            if (liveLink.toLowerCase().indexOf('youtube') > -1) {
                let url = new URL(liveLink)
                if (url.pathname.toLowerCase().indexOf('embed') > -1) {
                    return liveLink
                } else {
                    if (url.searchParams.get('list')) {
                        return url.origin + '/embed/?listType=playlist&list=' + url.searchParams.get('list')
                    }
                    return url.searchParams.get('v')
                }
            }
        }
        return liveLink
    }

    // console.log('Переданная ВидеоЛинк: ', props.liveLink)
    // console.log(isVideoAvailable)

    // console.log('Data', props.dayData)data
    let coeff = '280px';
    if (!props.dayData?.marquee) {
        coeff = '200px'
    }
    if (props.data?.days?.length > 1 && !props.dayData?.marquee) {
        coeff = '310px'
    }

    const opts = {
        height: '95%',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          controls: controls,
          disablekb: 1,
          loop: 0, 
        },
      };

    return (
        <div className="main-content">
            {!props.data && <Skeleton width="100%" height={`calc(100vh - ${coeff})`} className="mb-3" />}
            {props.data && (
                <>
                    <div className={props.isMarquee ? "video-container" : "video-container max"}>
                        {isBanner && <div style={{backgroundImage: 'url(' + liveLink + ')'}}  alt='' className="w-full banner"></div>}

                        {(!isBanner) && (
                            <>
                                {(controls === 0 || autoLink) && <div style={{opacity: opacity}} className='locker locker_top align-items-center' onClick={(e)=>{

                                        e.target.style.display = "none"

                                        if(opacity === 1){
                                            setOpacity(0);
                                            if(events){
                                                events.target.playVideo();
                                                if (events.target.isMuted()) {
                                                    events.target.unMute();
                                                }
                                            }
                                            if(autoLink){
                                                document.getElementById('_vd').muted = false
                                            }
                                        }
                                    }}>
                                    {(events!== false || autoLink) && <Button label={(liveLink ? 'Смотреть' : 'Включить звук')}/>}
                                </div>}

                                {(!liveLink && !timeBefore) && <video controls={controls} className="w-full auto-video" id="_vd" src={autoLink} contextMenu="false" autoPlay muted></video>}

                                {liveLink && <YouTube
                                    opts={opts}
                                    id='youtube_frame'
                                    style={{ height: `100%` }}
                                    className="w-full"
                                    videoId={getYoutubeCode(liveLink)}
                                    src={getYoutubeCode(liveLink) + '&rel=0&showinfo=0&autoplay=1&showinfo=0&modestbranding=1&playsinline=1'}
                                    onReady={(e) => events = e}
                                    title="YouTube video player"
                                    controls={controls}
                                    frameBorder="0"
                                    rel="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></YouTube>}
                                {controls === 0 && <div className='locker locker_bottom block md:visible'></div>}
                            </>
                        )}
                        {(!liveLink && !autoLink) && (
                            <div className="w-full video-unavailable">
                                <div>
                                    {timeBefore > 0 && <p>Трансляция начнется через {waitTime(timeBefore)} </p>}
                                    {timeBefore === 0 && <p>Загружаю информаии о трансляции...</p>}
                                </div>
                            </div>
                        )}
                        <div className="block lg:hidden">
                            {props.isMarquee && (
                                <div
                                    className="ticker"
                                    style={{
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Marquee scrollWhen="overflow" direction="left">
                                        {props.dayData?.marquee}
                                    </Marquee>
                                </div>
                            )}
                        </div>

                    </div>

                    {controls === 0 && <div className='block lg:hidden'>
                        <div className='locker locker_bottom'></div>
                        {/* <Days data={props.data} selectDay={props.selectDay} peoplesOnline={props.peoplesOnline} /> */}
                    </div>}
                </>

            )}
            {!props.data && <Skeleton width="100%" height="100px" />}
            {props.data && (
                <>
                    <div className="hidden lg:block">
                        {props.isMarquee && (
                            <div
                                className="ticker"
                                style={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Marquee scrollWhen="overflow" direction="left">
                                    {props.dayData?.marquee}
                                </Marquee>
                            </div>
                        )}
                    </div>

                    <div className="flex justify-content-between">
                        <div>
                            {/*  {props.isTimer && <Timer timerFinish={props?.dayData?.timer_finish} />} */}

                            <div className="block">
                                <Dialog header="Header" visible={showDialog} style={{ width: '50vw' }} onHide={() => setShowDialog(false)}>
                                </Dialog>
                                {/* <Button onClick={()=>setShowDialog(true)} label="Свяжитесь со мной" /> */}
                            </div>
                        </div>
                        {/* <div className="hidden lg:block content-info">
                            {peoplesOnline + 30 > 20 && <>
                                <div className="content-info-title">Сейчас смотрят</div>
                                <div className="content-info-data">{peoplesOnline + 30} человек</div>
                            </>}
                        </div> */}
                    </div>
                </>
            )}
        </div>
    )
}

export default VideoContainer
