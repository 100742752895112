import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useEffect, useRef } from "react"
import { useState } from "react"
import Gravatar from "react-gravatar"
import { Comment } from "./Comment"
import { ScrollPanel } from "primereact/scrollpanel"

import './Comments.css'
import { PeoplesOnline } from "../PeoplesOnline/PeoplesOnline"

export const Comments = ({ data, peoplesOnline, className }) => {


    const [replies, setReplies] = useState(null)
    const [text, setText] = useState('')
    const [textReply, setTextReply] = useState('')
    const [enterData, setEnterData] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [dataComments, setDataComments] = useState([{ id: 1, text: 'Find me at http://www.example.com and also at http://stackoverflow.com', username: 'First User', userId: 1, parentId: null, email: 'first@user.ru', createdAt: 'Mon Aug 07 2022 07:43:42 GMT+0300 (Москва, стандартное время)' }, { id: 2, text: 'Second Comment', username: 'Second User', userId: 2, parentId: null, email: 'second@user.ru', createdAt: 'Mon Aug 07 2022 14:27:42 GMT+0300 (Москва, стандартное время)' }, { id: 4, text: 'Third Comment', username: 'Third User', userId: 1, parentId: null, email: 'third@user.ru', createdAt: 'Mon Aug 04 2022 11:03:42 GMT+0300 (Москва, стандартное время)' }, { id: 4, text: 'First Comment', username: 'User', userId: 1, parentId: 1, email: 'test@user.ru', parentName: 'First User', createdAt: 'Mon Aug 07 2022 07:43:42 GMT+0300 (Москва, стандартное время)' }].sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
    }))
    const [howManyShow, setHowManyShow] = useState(10)
    const [noName, setNoName] = useState(false)
    const [noEmail, setNoEmail] = useState(false)
    const [user, setUser] = useState(null)
    const [comments, setComments] = useState([])
    const [emailError, setEmailError] = useState('')
    const [scrollBottom, setScrollBottom] = useState(false)
    const [writeName, setWriteName] = useState(false)
    const [timer, setTimer] = useState(0)


    const isDesktop = () => {
        return window.innerWidth >= 992
    }

    useEffect(() => {
        const slice = dataComments.length < howManyShow ? 0 : dataComments.length - howManyShow


        setComments(dataComments.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        }).slice(0, howManyShow))


    }, [howManyShow, dataComments])


    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")))


    }, [])


    const autoComment = data?.site?.comments?.filter(comment => comment.time === timer).map((item, idx) => {
        return { id: dataComments.length + idx + 1, username: item.name, email: item.email || item.name, admin: false, text: item.text, createdAt: new Date(), parentId: null }
    })

    useEffect(() => {
        setTimeout(() => setTimer(prevstate => prevstate + 1), 1000)
        if (autoComment) {
            setDataComments([...dataComments, ...autoComment])
        }

    }, [timer])


    const sendHandler = () => {
        setDataComments([...dataComments, { id: dataComments.length + 1, username: user.name, email: user.email, admin: user.admin, text: text, createdAt: new Date() }])
        setText('')
        setScrollBottom(true)
    }

    const getReplies = (id) => {
        return comments?.filter((item) => item.parentId === id).sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        })
    }

    const saveHandler = () => {
        setWriteName(false)
        const isValid = String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        if (name && email && isValid) {
            localStorage.setItem("user", JSON.stringify({ name: name, email: email, phone: phone, admin: true }));
            setUser(JSON.parse(localStorage.getItem("user")))

        } else {
            if (!name) {
                setNoName(true)
            }

            if (!email) {
                setNoEmail(true)
            } else {
                if (isValid) {
                    setEmailError('')
                } else {
                    setEmailError('Некорректный Email')
                }
            }
        }

    }

    const onEnterSend = (e) => {
        if (e.keyCode === 13 && text.length > 0) {
            sendHandler()
        }

    }

    const nextCommentHandler = () => {
        setHowManyShow(howManyShow + 10)
    }

    const nameHandler = (e) => {
        setName(e.target.value)
        setNoName(false)
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        setNoEmail(false)
    }

    const IconBtn = ({ color }) => {
        return <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.423361 19.5728C1.00128 20.1638 1.86815 20.0956 2.85358 19.6485L20.5393 11.5637C20.9913 11.3591 21.3543 11.1394 21.6062 10.8818C22.1323 10.3438 22.1323 9.66183 21.5988 9.11628C21.3543 8.86623 20.9913 8.63134 20.5393 8.42675L2.77207 0.319179C1.89038 -0.0824111 1.00869 -0.16576 0.43077 0.42526C-0.273102 1.14509 -0.0137815 1.95585 0.490044 2.92573L3.15735 8.0479C3.51299 8.745 3.82418 9.04808 4.52805 9.07081L20.4282 9.69214C20.6282 9.69972 20.7394 9.84369 20.7468 10.0028C20.7542 10.1619 20.6208 10.2983 20.4356 10.3059L4.53546 10.9575C3.87604 10.9954 3.5204 11.2985 3.15735 11.988L0.534499 17.0041C0.00103758 18.0043 -0.287921 18.8454 0.423361 19.5728Z" fill={color} />
        </svg>

    }

    const writeCommentHandler = () => {
        if (user) {
            setWriteName(false)
        } else {
            setWriteName(true)
        }
    }


    return (
        <div className={`flex flex-column comments-container ${className}`} >
            <div className="hidden lg:flex align-items-center comments-title-container justify-content-between">
                <h3 className="comments-title mb-0">Чат</h3>
                <PeoplesOnline data={data} peoplesOnline={peoplesOnline} />
            </div>

            {/* <div className="hidden lg:block">
                {dataComments.length > howManyShow &&
                    <div className="flex justify-content-center">
                        <Button className='next-comment-btn' onClick={nextCommentHandler}>Следующие комментарии</Button>
                    </div>
                }

            </div> */}
            <div className='comments flex flex-column justify-content-between'>
            <PeoplesOnline data={data} peoplesOnline={peoplesOnline} />
                <div className={dataComments.length > howManyShow ? "h-full lg:pt-0" : "h-full lg:pt-3"}>

                    {comments?.filter((item) => !item.parentId).map((item) => (
                        <div key={item.id} className="comment">
                            <Comment data={data} scrollBottom={scrollBottom} key={item.id} user={user} name={name} email={email} saveHandler={saveHandler} setName={setName} setEmail={email} comment={item} replies={getReplies(item.id)} reply={false} setDataComments={setDataComments} dataComments={dataComments} comments={comments} canReply={true} />
                        </div>

                    ))}
                    <div>
                        {dataComments.length > howManyShow &&
                            <div className="flex justify-content-center">
                                <Button className='next-comment-btn' onClick={nextCommentHandler}>Следующие комментарии</Button>
                            </div>
                        }

                    </div>
                </div>

            </div>
            {!writeName &&
                <div className="write-comment-container" >
                    <div className="flex write-comment mt-0 lg:mt-3">
                        <InputText className="write-comment-input w-full mr-3" value={text} onChange={(e) => setText(e.target.value)} onKeyDown={(e) => onEnterSend(e)} placeholder='Сообщение' onClick={writeCommentHandler} />
                        <button disabled={!text} className="send-comment-btn" onClick={sendHandler}>
                            <IconBtn color={data?.site?.color} />
                        </button>
                    </div>
                </div>}
            {writeName &&
                <div className="flex flex-column write-comment-container">

                    <div className="write-data w-full">
                        <p className="my-3 title-data">Представьтесь чтобы писать комментарии в чате</p>
                        <div className="flex flex-wrap w-full mb-2">
                            <div className="w-full">
                                <InputText className={noName ? "p-invalid user-data w-full" : "user-data w-full"} value={name} onChange={(e) => nameHandler(e)} placeholder='Ваше имя*' />
                                {noName && <small id="title-help" className="p-error block">Введите Ваше имя</small>}
                            </div>
                            <div className="w-full">
                                <InputText className={noEmail ? "p-invalid user-data w-full" : "user-data w-full"} value={email} onChange={(e) => emailHandler(e)} placeholder='Ваш email*' />
                                {noEmail && <small id="title-help" className="p-error block">Введите Ваш Email</small>}
                                {emailError && <small id="title-help" className="p-error block">{emailError}</small>}
                            </div>
                        </div>
                        <div className="px-2">
                            <Button className="join-chat-btn" label="Присоединиться к чату" onClick={saveHandler} />
                        </div>

                    </div>

                </div>
            }
        </div>

    )
}