import { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import './Payment.css'
import { Button } from 'primereact/button';
import { payment } from '../../service/api';


export const Payment = (props) => {
    const formatNumber = (number) => {
        return Intl.NumberFormat('ru-RU').format(number)
    }
    const currency = [
        { name: 'Российский рубль, RUB', symbol: '₽', code: 'rub', sum: props.amount.rub },
        { name: 'Евро, EUR', symbol: '€', code: 'eur', sum: props.amount.usd },
        { name: 'Доллар, USD', symbol: '$', code: 'usd', sum: props.amount.eur },
        { name: 'Гривна, UAH', symbol: '₴', code: 'uah', sum: props.amount.uah }
    ]

    const [isSuccessPayment, setIsSuccessPayment] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedMonthTinkoff, setSelectedMonthTinkoff] = useState(3)
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [filters, setFilters] = useState(null)
    const [paymentTypes, setPaymentTypes] = useState([])
    const [currencyType, setCurrencyType] = useState([])
    const [site, setSite] = useState(null)

    useEffect(() => {
        if (selectedPayment) {
            setTimeout(() => {
                checkURL()
            }, 1000);
        }
    }, [selectedPayment])


    const typeCurrency = () => {
        const arr = []
        paymentTypes.forEach((item) => {
            if (item.currency) {
                item.currency.forEach((it) => {
                    if (!arr.includes(it)) {
                        arr.push(it)
                    }
                })
            }
        })
        return arr
    }



    useEffect(() => {
        payment(props.invoice_id)
            .then((response) => {
                setSite(response.data)
                setCurrencyType(props.amount)
                setPaymentTypes(props.payment_types)

            })
            .catch((error) => console.error(error))

    }, [])

    useEffect(() => {

        let arr = []
        currency.map((item) => {
            if (typeCurrency().includes(item.code.toUpperCase())) {
                arr.push(item)
            }

        })

        setFilters(arr)
        setSelectedCurrency(arr[0])

    }, [paymentTypes])


    // const [selectedMonth, setSelectedMonth] = useState(3)
    const buttons = []
    props.payment_types.forEach(el => {
        if (el.payment_type === 'TinkoffCredit') {
            Object.keys(el.installment).forEach(key => {
                buttons.push(key)
            })

        }
    })

    const checkURL = () => {
        try {
            const link = document.getElementById("iframe").contentWindow.location.href;

            if (link.indexOf('success.html') > 0) {
                setIsSuccessPayment(true)
            } else {
                setTimeout(() => {
                    checkURL()
                }, 2000);
            }
        } catch (error) {
            setTimeout(() => {
                checkURL()
            }, 2000);
        }

    }

    if (selectedPayment && !isSuccessPayment) {
        return <iframe style={{ width: "100%", height: "560px" }} title='pay' id="iframe" src={"https://backend.ashahov.ru/api/payment?apply_config=" + props.apply_config_id + "&currency=" + selectedCurrency.code.toUpperCase() + "&payment_type=" + selectedPayment + "&apply_id=" + props.invoice_id + "&email=" + props.email + (selectedPayment === 'TinkoffCredit' ? '&duration=' + selectedMonthTinkoff : '')} frameBorder="0"></iframe>
    }

    if (isSuccessPayment) {
        return <p>✓ Спасибо, что преобрели наш курс</p>
    }

    const tinkoff = paymentTypes.find((item) => item.payment_type === 'TinkoffCredit')
    const otp = paymentTypes.find((item) => item.payment_type === 'OTP')

    const isCredit = () =>
    (
        (
            paymentTypes.find((item) => item.payment_type === 'TinkoffCredit')
            ||
            paymentTypes.find((item) => item.payment_type === 'OTP')
        )
        && currencyType['rub'] > 2000
    )

    return (
        <>
            {selectedCurrency &&
                <div className='grid m-0 w-full'>
                    <div className={selectedCurrency?.code === 'rub' && isCredit() ? 'col-12 md:col-6 lg:col-12 pr-0 md:pr-3 lg:pr-0' : 'col-12 grid m-0'}>
                        <div className={selectedCurrency?.code === 'rub' && isCredit() ? 'col-12 mt-0 md:mt-3 lg:mt-0' : 'col-12 md:col-6 lg:col-12 mt-0 md:mt-2 lg:mt-0'}>
                            <h3 className="title">Оплата участия</h3>
                            <p className="course my-5">{site?.name}</p>
                            <p className="email">{props.email}</p>
                        </div>

                        <div className={selectedCurrency?.code === 'rub' && isCredit() ? 'col-12' : 'col-12 sm:col-6 lg:col-12 mt-0 sm:mt-2 lg:mt-0'}>
                            <h5 className='select-title'>Выберите способ и валюту оплаты.</h5>

                            <Dropdown className='select w-full' value={selectedCurrency} options={filters} onChange={(e) => setSelectedCurrency(e.value)} optionLabel="name" />
                            <div>
                                <p className="select-title mt-5 mb-0">К оплате</p>
                                <p className="sum">
                                    {Intl.NumberFormat('ru-RU').format(currencyType[selectedCurrency?.code])} {selectedCurrency?.symbol}
                                </p>
                                <Button className='btn-pay w-full' onClick={() => { (selectedCurrency.code === currency[0].code) ? setSelectedPayment('CloudPayments') : setSelectedPayment('WayForPay') }} label='Оплатить' />

                                {selectedCurrency && <p className="pay-text mt-3">Оплата с помощью {paymentTypes?.find((item) => item?.currency?.includes(selectedCurrency?.code.toUpperCase())).payment_type}</p>}
                            </div>
                        </div>
                    </div>

                    <div className={selectedCurrency?.code === 'rub' && isCredit() ? 'col-12 md:col-6 lg:col-12 flex flex-column align-items-center mt-5' : 'hidden'}>
                        <div className="w-full flex flex-column  align-items-center justify-content-center">
                            {otp && (
                                <div className="card-bank w-full relative mb-6">
                                    <img className="absolute otp" src="/assets/otp.svg" alt="" />
                                    <p className="mt-5 sum-payment">
                                        от {Math.ceil(selectedCurrency?.sum / 12)} {selectedCurrency?.symbol}/мес
                                    </p>
                                    <p className="otp-text mt-4">Страница для оформления рассрочки от ОТП Банк откроется в новой вкладке.</p>
                                    <div className="px-0">
                                        <a className="no-underline w-full flex justify-content-center" href={otp?.link}>
                                            <Button className="btn-apply green w-10 mt-5" label="Оформить" />
                                        </a>
                                    </div>
                                </div>
                            )}
                            {(tinkoff && currencyType['rub'] > 3164 && currencyType['rub'] < 52738) && (
                                <div className="card-bank relative w-full mb-6">
                                    <img className="absolute tinkoff" src="/assets/tinkoff.svg" alt="" />
                                    <p className="mt-5 sum-payment">
                                        от {Math.ceil(selectedCurrency?.sum / selectedMonthTinkoff)} {selectedCurrency?.symbol}/мес
                                    </p>
                                    <div className="buttons p-1 mt-3">
                                        {Object.keys(tinkoff.installment).map((item, idx) => {

                                            return (
                                                <Button key={idx} className={+item === selectedMonthTinkoff ? 'btn-month active mr-3' : 'btn-month mr-3'} onClick={() => setSelectedMonthTinkoff(+item)}>
                                                    {item} {+item === 3 || +item === 24 ? 'месяца' : 'месяцев'}
                                                </Button>
                                            )
                                        })}
                                    </div>

                                    <div className="px-0 flex justify-content-center">
                                        <Button className='btn-apply yellow w-10 mt-5' onClick={() => setSelectedPayment('TinkoffCredit')} label='Оформить' />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            }
        </>
    )
}