import { useEffect, useState } from 'react';
import io from 'socket.io-client'
import './PeoplesOnline.css'



export const PeoplesOnline = ({ data, peoplesOnline }) => {

    const Icon = ({ color }) => {
        return <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.52407 16H13.476C13.9513 16 14.3242 15.8947 14.5945 15.6841C14.8648 15.4735 15 15.1842 15 14.8164C15 14.2469 14.8262 13.6447 14.4787 13.01C14.1311 12.3752 13.632 11.7805 12.9813 11.2258C12.3307 10.6711 11.5434 10.2202 10.6194 9.87315C9.69551 9.52609 8.65422 9.35256 7.49556 9.35256C6.34286 9.35256 5.30453 9.52609 4.38058 9.87315C3.45663 10.2202 2.66786 10.6711 2.01427 11.2258C1.36067 11.7805 0.861558 12.3752 0.516935 13.01C0.172312 13.6447 0 14.2469 0 14.8164C0 15.1842 0.135176 15.4735 0.405527 15.6841C0.675878 15.8947 1.04873 16 1.52407 16ZM7.50448 7.75971C8.14025 7.75971 8.72847 7.58619 9.26914 7.23914C9.80986 6.89208 10.2451 6.42193 10.5749 5.82868C10.9047 5.23544 11.0695 4.56803 11.0695 3.82646C11.0695 3.09676 10.9047 2.44271 10.5749 1.86429C10.2451 1.28587 9.80986 0.830553 9.26914 0.49833C8.72847 0.16611 8.14025 0 7.50448 0C6.86871 0 6.28047 0.169076 5.73977 0.507229C5.19906 0.845383 4.76383 1.30515 4.43406 1.88654C4.10429 2.46792 3.93941 3.1205 3.93941 3.84427C3.93941 4.57989 4.10429 5.24285 4.43406 5.83313C4.76383 6.42342 5.19906 6.89208 5.73977 7.23914C6.28047 7.58619 6.86871 7.75971 7.50448 7.75971Z" fill={color} />
        </svg>

    }


    return (
        <>
            <div className=" content-info">
                {(peoplesOnline + 30 > 20) && <div className='flex ml-3'>
                    <Icon color={data?.site?.color} />
                    <div className="header-people-online ml-2">{peoplesOnline + 30}</div>
                </div>}
            </div>
        </>

    )
}