import React, { useState, useEffect } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import './FacultiesTabs.scss'
import Tab from './Tab'
import Timer from '../Timer/Timer'

const FacultiesTabs = (props) => {

    const [activeIndex, setActiveIndex] = useState(0)
    const [faculties, setFaculties] = useState([])
    const [showForm, setShowForm] = useState(false)
    // console.log('faculties', faculties)

    useEffect(() => {
        setFaculties(props.data?.forms.sort((a, b) => Number(b.is_default) - Number(a.is_default)))
    }, [props.data])


    const prevTab = () => {
        if (activeIndex > 0) {
            setActiveIndex((activeIndex) => activeIndex - 1)
        } else {
            setActiveIndex(faculties.length - 1)
        }
    }
    const nextTab = () => {
        if (activeIndex < faculties.length - 1) {
            setActiveIndex((activeIndex) => activeIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }
    return (
        <div className='grid m-0'>
            <div className={showForm ? "hidden" : "col-12 p-0 flex justify-content-center"}>
                <div className={'timer-container mb-3'}>
                    <div className="timer-block flex justify-content-between align-items-center">
                        <div>
                            <div onClick={() => setShowForm(true)} className="timer-block-title">Действует специальное предложение</div>
                            {props.isTimer && <Timer timerFinish={props.dayData?.timer_finish} />}
                        </div>
                        <Button className='timer-btn' onClick={() => setShowForm(true)}>Забронировать</Button>
                    </div>

                </div>
            </div>
            <div className="col-12 p-0 flex justify-content-center">
                {(props.data && showForm) && (
                    <div className="tabs mb-3">
                        <div className="tabs-nav">
                            {(faculties && faculties.length > 1) && <Button icon="pi pi-arrow-left" onClick={() => prevTab()} className="p-button-text mr-1" />}
                            <div>
                                <h2 style={{ textAlign: (faculties && faculties.length === 1) ? 'left' : '' }}>{faculties ? faculties[activeIndex]?.title : ''}</h2>
                            </div>
                            {(faculties && faculties.length > 1) && <Button icon="pi pi-arrow-right" onClick={() => nextTab()} className="p-button-text" />}
                        </div>

                        <TabView className="tabs-content" scrollable={true} style={{ fontSize: '12px' }} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            {faculties?.map((item, index) => {
                                return (
                                    <TabPanel key={index}>
                                        <Tab item={item} site={props.data.site} showForm={showForm} setShowForm={setShowForm} isTimer={props.isTimer} dayData={props.dayData} />
                                    </TabPanel>
                                )
                            })}
                        </TabView>
                    </div>
                )}
            </div>
            {!props.data && <Skeleton width="85%" height="750px" />}
        </div>
    )
}

export default FacultiesTabs
