import React, { useState, useEffect } from 'react'
import moment, { duration } from 'moment'
import './Timer.css'
import { Button } from 'primereact/button'

const Timer = (props) => {
    const [isVisible, setIsVisible] = useState(true)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    let interval = null
    const setCountdown = () => {
        setIsVisible(true)
        const currentTime = moment().format()
        const timerFinish = moment(props.timerFinish).format()
        const clockDuration = duration(moment(timerFinish).diff(currentTime))

        const hours = clockDuration?.hours()
        const minutes = clockDuration?.minutes()
        const seconds = clockDuration?.seconds()
        if (hours < 1 && minutes < 1 && seconds < 1) {
            setHours(0)
            setMinutes(0)
            setSeconds(0)

            setIsVisible(false)
            console.log('REDIRECT')

            clearInterval(interval)
        }

        setHours(hours)
        setMinutes(minutes)
        setSeconds(seconds)
    }

    useEffect(() => {
        setCountdown()

        interval = setInterval(() => {
            setCountdown()
        }, 1000)

        return () => clearInterval(interval)
    }, [props.timerFinish])

    return (
        <>
            <div className=" content-info">
                {/* <div className="content-info-title">Данное предложение актуально ещё:</div> */}
                <div className="content-info-data flex">
                    <div className="">
                        <div className="time hour ">{hours.toString().length < 2 ? '0' + hours : hours}:</div>
                    </div>
                    <div className="">
                        <div className="time minute">{minutes.toString().length < 2 ? '0' + minutes : minutes}:</div>
                    </div>
                    <div className="">
                        <div className="time second">{seconds.toString().length < 2 ? '0' + seconds : seconds}</div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Timer
